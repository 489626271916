@import '@/styles/variables';

/**
* login ve register butonları (giriş yap ve kayıt ol butonları css)
**/

.authButton {
  background-color: $brown-600;
  color: $white-100;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  transition: all 0.2s linear;
  border: 1px solid $black-950;
  box-shadow:
    inset 0 -2px 5px $black-shadow-50,
    1px 2px 5px $black-700;

  &:hover {
    background-color: $brown-500;
  }
}

/**
* sosyal medya butonları
**/

.socialMediaButton {
  background-color: $black-600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid $black-950;
  box-shadow:
    inset 0 -1px 4px 1px $black-shadow-50,
    1px 2px 5px $black-700;

  &:hover {
    background-color: $black-700;
    transition: all 0.3s ease-in-out;
  }
}
