@import '@/styles/variables';
@import '@/styles/breakpoints';

.effect {
  background: radial-gradient(ellipse, rgba(140, 148, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  width: 25vw;
  height: 50vh;
  filter: blur(10px);
  z-index: -1;
}

.headerImages {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1.5rem auto;
  gap: 1rem;
  overflow: hidden;

  @include responsive('lg') {
    margin: 0 auto;
    height: 3rem;
  }

  & > img {
    width: auto;
    height: 2rem;

    @include responsive('sm') {
      height: 2.3rem;
    }

    @include responsive('lg') {
      height: 2.5rem;
    }

    &:nth-child(2) {
      height: 2.5rem;

      @include responsive('sm') {
        height: 3rem;
      }
    }
  }

  & > img:nth-child(3) {
    transform: scaleX(-1);
  }
}

.welcomeText {
  font-size: x-large;
  text-align: center;
  margin: 0.5rem auto 1.5rem auto;
  color: transparent;
  background: linear-gradient(to right, $brown-500, $black-200);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: min-content;
  letter-spacing: 2px;

  @include responsive('xxs') {
    margin: 2.5rem auto 1.5rem auto;
  }

  @include responsive('lg') {
    font-size: xx-large;
    margin: 3.5rem auto 2.5rem auto;
  }
}

.noAccountText {
  font-size: 1rem;
  text-align: center;
  color: $gray-400;
  margin-bottom: 2.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;

  @include responsive('lg') {
    font-size: 1.1rem;
  }

  & > a {
    color: $white-100;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
      color: $blue-200;
    }
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & input {
    &::placeholder {
      font-size: smaller;
    }
  }

  .forgotPassword {
    font-size: 1rem;
    text-align: center;
    color: $gray-400;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.3rem;

    @include responsive('lg') {
      font-size: 1.1rem;
    }

    & > a {
      color: $white-100;
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        text-decoration: none;
        color: $blue-200;
      }
    }
  }
}

.separatorContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 2.5rem auto;
  gap: 1.5rem;

  .separator {
    flex-grow: 1;
    height: 1px;
    background-color: $white-50;
    opacity: 0.2;

    .text {
      color: $white-50;
      padding: 0 1rem;
      font-size: 1rem;
    }
  }
}

.socialButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  width: 100%;
  gap: 1rem;

  & > button > img {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }
}
